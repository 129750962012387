import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://vivaspanish.app:8444/api/test/';
//const API_URL = 'http://localhost:8080/api/test/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getQuizCategoriesBoard() {
    return axios.get(API_URL + 'quizCategories', {headers: authHeader() });
  }

  getQuizBoard(id) {
    return axios.get(API_URL + 'quiz?id='+id, {headers: authHeader() });
  }
  
  addCategory(name, image){
    const img = encodeURI(image)
    return axios.get(API_URL + 'addItem?type=category&name='+name+'&image='+img, {headers: authHeader() });
  }
  updateCategory(id, name, image){
    const img = encodeURI(image)
    return axios.get(API_URL + 'addItem?type=categoryUpdate&id='+id+'&name='+name+'&image='+img, {headers: authHeader() });
  }
  getQuizQuestions(id) {
    return axios.get(API_URL + 'quizQuestions?id='+id, {headers: authHeader() });
  }
  getQuizQuestion(id) {
    return axios.get(API_URL + 'quizQuestion?id='+id, {headers: authHeader() });
  }
  addQuestion(question, answer, catId){
    return axios.get(API_URL + 'addItem?type=question&question='+question+'&answer='+answer+'&catId='+catId, {headers: authHeader() });
  }
  updateQuestion(question, answer, questionId){
    return axios.get(API_URL + 'addItem?type=questionUpdate&question='+question+'&answer='+answer+'&questionId='+questionId, {headers: authHeader() });
  }
  deleteQuestion(questionId){
    return axios.get(API_URL + 'addItem?type=questionDelete&questionId='+questionId, {headers: authHeader() });
  }
  deleteCategory(catId){
    return axios.get(API_URL + 'addItem?type=categoryDelete&id='+catId, {headers: authHeader() });
  }
  captureScore(category, score, attempts){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'captureScore?category='+category+'&userId='+user.id+'&score='+score+'&attempts='+attempts, {headers: authHeader() });
  }
  captureRoundScore(battleId, score){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'captureRoundScore?battleId='+battleId+'&userId='+user.id+'&score='+score, {headers: authHeader() });
  }
  getLeaderBoard(){
    return axios.get(API_URL + 'leaderBoard', {headers: authHeader() });
  }
  getBattleInvites(){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'battleInvites?userId='+user.id, {headers: authHeader() })
  }
  acceptBattleInvite(battleId, response){
    let user = JSON.parse(localStorage.getItem('user'));
    let res;
    if(response==true) res = Date.now();
    else res = -1;
    return axios.get(API_URL + 'acceptBattle?userId='+user.id+'&battleId='+battleId+'&res='+res, {headers: authHeader() })
  }
  startBattle(opponent, rounds, catID){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'startBattle?userId='+user.id+'&category='+catID+'&opponent='+opponent+'&rounds='+rounds, {headers: authHeader() })
  }
  inviteLeague(user, leagueId, ){
    return axios.get(API_URL + 'inviteLeague?userId='+user+'&league='+leagueId, {headers: authHeader() })
  }
  getLeagueInvites(){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'leagueInvites?userId='+user.id, {headers: authHeader() })
  }
  acceptLeagueInvite(leagueId, response){
    let user = JSON.parse(localStorage.getItem('user'));
    let res;
    if(response==true) res = Math.floor(Date.now() / 1000);
    else res = -1;
    return axios.get(API_URL + 'acceptLeague?userId='+user.id+'&leagueId='+leagueId+'&accepted='+res, {headers: authHeader() })
  }
  startLeague(leagueName){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'startLeague?userId='+user.id+'&leagueName='+leagueName, {headers: authHeader() })
  }
  getLeagues(){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'getLeagues?userId='+user.id, {headers: authHeader() })
  }
  getLeague(leagueId){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'getLeague?userId='+user.id+'&leagueId='+leagueId, {headers: authHeader() })
  }
  getBattles(){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'getBattles?userId='+user.id, {headers: authHeader() })
  }
  getBattle(battleId){
    let user = JSON.parse(localStorage.getItem('user'));
    return axios.get(API_URL + 'getBattle?userId='+user.id+'&battleId='+battleId, {headers: authHeader() })
  }
}

export default new UserService();
