<template>
  <div class="container">
    <header>
      <h1 style="padding:2%">Welcome to the Spanish Quiz</h1>
      <div class="row">
        <div class="col-md">
          <p>Hello and welcome to the ultimate Spanish language quiz. Get started by clicking on quiz and choose which category you wish to test your Spanish knowledge in.</p>
          <h2>Latest News</h2>
          <p>The latest news from Spanish Lee.</p>
          <h4>* The new App is now Live</h4>
          <p>The new Spanish quiz app is now live and you are on it and using it. Muy bien!</p>
        </div>
        <div class="col-md"><img src="@/assets/stickman/cheering.png"/></div>
      </div>
     
      
    </header>
  </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "Home",
  data() {
    return {
      content: "Welcome to the Spanish Quiz",
    };
  },
  mounted() {
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>
