<template>
  <div id="app" class="app-background">
    
      <nav class="navbar navbar-expand-lg nav-bar-SL">
        <div class="container-fluid">
          <a href="#" class="navbar-brand"><img src="@/assets/spanishLogo.png" height="120" /></a>
              <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <font-awesome-icon icon="bars" />
              </button>
              <div class="collapse navbar-collapse" id="navbarCollapse">
                  <div class="navbar-nav ">
                    <router-link to="/home" class="nav-item nav-link hvr-bounce-in">Home</router-link>
                    <router-link v-if="currentUser" to="/quizCategories" class="nav-item nav-link hvr-bounce-in">Quiz</router-link>
                    <router-link v-if="currentUser" to="/leaderBoard" class="nav-item nav-link hvr-bounce-in">Leaderboard</router-link>
                    <router-link v-if="currentUser" to="/leagues" class="nav-item nav-link hvr-bounce-in">Leagues</router-link>
                    <router-link v-if="currentUser" to="/battles" class="nav-item nav-link hvr-bounce-in">Battles</router-link>
                  </div>
                    <div v-if="!currentUser" class="navbar-nav ms-auto">
                      <router-link to="/login" class="nav-item nav-link"><font-awesome-icon icon="sign-in-alt" /> Login</router-link>
                      <router-link to="/register" class="nav-item nav-link"><font-awesome-icon icon="user-plus" /> Sign Up </router-link>
                    </div>
                    <div v-if="currentUser" class="navbar-nav ms-auto ">
                      <router-link to="/profile" class="nav-item nav-link hvr-bounce-in"> <font-awesome-icon icon="user" /> {{ currentUser.username }}</router-link>
                      <router-link v-if="showAdminBoard" to="/admin" class="nav-item nav-link hvr-bounce-in">Admin Board</router-link>
                      <router-link v-if="showModeratorBoard" to="/mod" class="nav-item nav-link hvr-bounce-in">Moderator Board</router-link>
                      <a class="nav-item nav-link hvr-bounce-in" @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" /></a>
                    </div>
                </div>
        </div>
      </nav>
    <div class="container hand-drawn-borders">
      
      <router-view />
    </div>
  </div>
  <footer>
  <div class="text-center p-3">
      ©
      <span>Spanish Lee Quiz</span>
    </div>
    <!-- Copyright -->
  </footer>
</template>
<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>
