import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome';
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

createApp(App)
  .use(router)
  .use(store)
  .use(PrimeVue)
  .component("DataTable",DataTable)
  .component("Column",Column)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("Button",Button)
  .component("Dialog",Dialog)
  .mount("#app");
